<template>
  <div class="auth-wrapper">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "empty",
};
</script>

<style lang="scss" scoped>
.auth-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100vh;
}
</style>